/*
 * @license MIT */

(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(factory);
  } else if (typeof exports === 'object') {
    module.exports = factory();
  } else {
    root.XProgress = factory();
  }
})(this, function () {
  const XProgress = {};

  XProgress.version = '0.2.0';

  const Settings = (XProgress.settings = {
    minimum: 0.08,
    easing: 'ease',
    positionUsing: '',
    speed: 200,
    trickle: true,
    trickleRate: 0.02,
    trickleSpeed: 800,
    showSpinner: true,
    barSelector: '[role="bar"]',
    spinnerSelector: '[role="spinner"]',
    parent: 'body',
    template:
      '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
  });

  /**
   * Updates configuration.
   *
   *     XProgress.configure({
   *       minimum: 0.1
   *     });
   */
  XProgress.configure = function (options) {
    let key;
    let value;
    for (key in options) {
      value = options[key];
      if (value !== undefined && options.hasOwnProperty(key)) Settings[key] = value;
    }

    return this;
  };

  /**
   * Last number.
   */

  XProgress.status = null;

  /**
   * Sets the progress bar status, where `n` is a number from `0.0` to `1.0`.
   *
   *     XProgress.set(0.4);
   *     XProgress.set(1.0);
   */

  XProgress.set = function (n) {
    const started = XProgress.isStarted();

    n = clamp(n, Settings.minimum, 1);
    XProgress.status = n === 1 ? null : n;

    const progress = XProgress.render(!started);
    const bar = progress.querySelector(Settings.barSelector);
    const { speed } = Settings;
    const ease = Settings.easing;

    progress.offsetWidth; /* Repaint */

    queue(function (next) {
      // Set positionUsing if it hasn't already been set
      if (Settings.positionUsing === '') Settings.positionUsing = XProgress.getPositioningCSS();

      // Add transition
      css(bar, barPositionCSS(n, speed, ease));

      if (n === 1) {
        // Fade out
        css(progress, {
          transition: 'none',
          opacity: 1
        });
        progress.offsetWidth; /* Repaint */

        setTimeout(function () {
          css(progress, {
            transition: `all ${speed}ms linear`,
            opacity: 0
          });
          setTimeout(function () {
            XProgress.remove();
            next();
          }, speed);
        }, speed);
      } else {
        setTimeout(next, speed);
      }
    });

    return this;
  };

  XProgress.isStarted = function () {
    return typeof XProgress.status === 'number';
  };

  /**
   * Shows the progress bar.
   * This is the same as setting the status to 0%, except that it doesn't go backwards.
   *
   *     XProgress.start();
   *
   */
  XProgress.start = function () {
    if (!XProgress.status) XProgress.set(0);

    var work = function () {
      setTimeout(function () {
        if (!XProgress.status) return;
        XProgress.trickle();
        work();
      }, Settings.trickleSpeed);
    };

    if (Settings.trickle) work();

    return this;
  };

  /**
   * Hides the progress bar.
   * This is the *sort of* the same as setting the status to 100%, with the
   * difference being `done()` makes some placebo effect of some realistic motion.
   *
   *     XProgress.done();
   *
   * If `true` is passed, it will show the progress bar even if its hidden.
   *
   *     XProgress.done(true);
   */

  XProgress.done = function (force) {
    if (!force && !XProgress.status) return this;

    return XProgress.inc(0.3 + 0.5 * Math.random()).set(1);
  };

  /**
   * Increments by a random amount.
   */

  XProgress.inc = function (amount) {
    let n = XProgress.status;

    if (!n) {
      return XProgress.start();
    }
    if (typeof amount !== 'number') {
      amount = (1 - n) * clamp(Math.random() * n, 0.1, 0.95);
    }

    n = clamp(n + amount, 0, 0.994);
    return XProgress.set(n);
  };

  XProgress.trickle = function () {
    return XProgress.inc(Math.random() * Settings.trickleRate);
  };

  /**
   * Waits for all supplied jQuery promises and
   * increases the progress as the promises resolve.
   *
   * @param $promise jQUery Promise
   */
  (function () {
    let initial = 0;
    let current = 0;

    XProgress.promise = function ($promise) {
      if (!$promise || $promise.state() === 'resolved') {
        return this;
      }

      if (current === 0) {
        XProgress.start();
      }

      initial++;
      current++;

      $promise.always(function () {
        current--;
        if (current === 0) {
          initial = 0;
          XProgress.done();
        } else {
          XProgress.set((initial - current) / initial);
        }
      });

      return this;
    };
  })();

  /**
   * (Internal) renders the progress bar markup based on the `template`
   * setting.
   */

  XProgress.render = function (fromStart) {
    if (XProgress.isRendered()) return document.getElementById('nxprogress');

    addClass(document.documentElement, 'nxprogress-busy');

    const progress = document.createElement('div');
    progress.id = 'nxprogress';
    progress.innerHTML = Settings.template;

    const bar = progress.querySelector(Settings.barSelector);
    const perc = fromStart ? '-100' : toBarPerc(XProgress.status || 0);
    const parent = document.querySelector(Settings.parent);
    let spinner;

    css(bar, {
      transition: 'all 0 linear',
      transform: `translate3d(${perc}%,0,0)`
    });

    if (!Settings.showSpinner) {
      spinner = progress.querySelector(Settings.spinnerSelector);
      spinner && removeElement(spinner);
    }

    if (parent != document.body) {
      addClass(parent, 'nprogress-custom-parent');
    }
    if (parent) {
      parent.appendChild(progress);
    }
    return progress;
  };

  /**
   * Removes the element. Opposite of render().
   */

  XProgress.remove = function () {
    removeClass(document.documentElement, 'nxprogress-busy');
    removeClass(document.querySelector(Settings.parent), 'nprogress-custom-parent');
    const progress = document.getElementById('nxprogress');
    progress && removeElement(progress);
  };

  /**
   * Checks if the progress bar is rendered.
   */

  XProgress.isRendered = function () {
    return !!document.getElementById('nxprogress');
  };

  /**
   * Determine which positioning CSS rule to use.
   */

  XProgress.getPositioningCSS = function () {
    // Sniff on document.body.style
    const bodyStyle = document.body.style;

    // Sniff prefixes
    const vendorPrefix =
      'WebkitTransform' in bodyStyle
        ? 'Webkit'
        : 'MozTransform' in bodyStyle
        ? 'Moz'
        : 'msTransform' in bodyStyle
        ? 'ms'
        : 'OTransform' in bodyStyle
        ? 'O'
        : '';

    if (`${vendorPrefix}Perspective` in bodyStyle) {
      // Modern browsers with 3D support, e.g. Webkit, IE10
      return 'translate3d';
    }
    if (`${vendorPrefix}Transform` in bodyStyle) {
      // Browsers without 3D support, e.g. IE9
      return 'translate';
    }
    // Browsers without translate() support, e.g. IE7-8
    return 'margin';
  };

  /**
   * Helpers
   */

  function clamp(n, min, max) {
    if (n < min) return min;
    if (n > max) return max;
    return n;
  }

  /**
   * (Internal) converts a percentage (`0..1`) to a bar translateX
   * percentage (`-100%..0%`).
   */

  function toBarPerc(n) {
    return (-1 + n) * 100;
  }

  /**
   * (Internal) returns the correct CSS for changing the bar's
   * position given an n percentage, and speed and ease from Settings
   */

  function barPositionCSS(n, speed, ease) {
    let barCSS;

    if (Settings.positionUsing === 'translate3d') {
      barCSS = { transform: `translate3d(${toBarPerc(n)}%,0,0)` };
    } else if (Settings.positionUsing === 'translate') {
      barCSS = { transform: `translate(${toBarPerc(n)}%,0)` };
    } else {
      barCSS = { 'margin-left': `${toBarPerc(n)}%` };
    }

    barCSS.transition = `all ${speed}ms ${ease}`;

    return barCSS;
  }

  /**
   * (Internal) Queues a function to be executed.
   */

  var queue = (function () {
    const pending = [];

    function next() {
      const fn = pending.shift();
      if (fn) {
        fn(next);
      }
    }

    return function (fn) {
      pending.push(fn);
      if (pending.length == 1) next();
    };
  })();

  /**
   * (Internal) Applies css properties to an element, similar to the jQuery
   * css method.
   *
   * While this helper does assist with vendor prefixed property names, it
   * does not perform any manipulation of values prior to setting styles.
   */

  var css = (function () {
    const cssPrefixes = ['Webkit', 'O', 'Moz', 'ms'];
    const cssProps = {};

    function camelCase(string) {
      return string.replace(/^-ms-/, 'ms-').replace(/-([\da-z])/gi, function (match, letter) {
        return letter.toUpperCase();
      });
    }

    function getVendorProp(name) {
      const { style } = document.body;
      if (name in style) return name;

      let i = cssPrefixes.length;
      const capName = name.charAt(0).toUpperCase() + name.slice(1);
      let vendorName;
      while (i--) {
        vendorName = cssPrefixes[i] + capName;
        if (vendorName in style) return vendorName;
      }

      return name;
    }

    function getStyleProp(name) {
      name = camelCase(name);
      return cssProps[name] || (cssProps[name] = getVendorProp(name));
    }

    function applyCss(element, prop, value) {
      prop = getStyleProp(prop);
      element.style[prop] = value;
    }

    return function (element, properties) {
      const args = arguments;
      let prop;
      let value;

      if (args.length == 2) {
        for (prop in properties) {
          value = properties[prop];
          if (value !== undefined && properties.hasOwnProperty(prop))
            applyCss(element, prop, value);
        }
      } else {
        applyCss(element, args[1], args[2]);
      }
    };
  })();

  /**
   * (Internal) Determines if an element or space separated list of class names contains a class name.
   */

  function hasClass(element, name) {
    if (!element) {
      return;
    }
    const list = typeof element === 'string' ? element : classList(element);
    return list.indexOf(` ${name} `) >= 0;
  }

  /**
   * (Internal) Adds a class to an element.
   */

  function addClass(element, name) {
    if (!element) {
      return;
    }
    const oldList = classList(element);
    const newList = oldList + name;

    if (hasClass(oldList, name)) return;

    // Trim the opening space.
    element.className = newList.substring(1);
  }

  /**
   * (Internal) Removes a class from an element.
   */

  function removeClass(element, name) {
    if (!element) {
      return;
    }
    const oldList = classList(element);
    let newList;

    if (!hasClass(element, name)) return;

    // Replace the class name.
    newList = oldList.replace(` ${name} `, ' ');

    // Trim the opening and closing spaces.
    element.className = newList.substring(1, newList.length - 1);
  }

  /**
   * (Internal) Gets a space separated list of the class names on the element.
   * The list is wrapped with a single space on each end to facilitate finding
   * matches within the list.
   */

  function classList(element) {
    if (element) {
      return ` ${element.className || ''} `.replace(/\s+/gi, ' ');
    }
  }

  /**
   * (Internal) Removes an element from the DOM.
   */

  function removeElement(element) {
    element && element.parentNode && element.parentNode.removeChild(element);
  }

  return XProgress;
});
