import localForage from '@/lib/localForage';
// check persist approach
// https://github.com/tiaanduplessis/react-hook-form-persist

export const saveToLocalResponse = async ({ modelID, responseBody }, customKey) => {
  // console.log('on util');
  // console.log({ modelID, responseBody });
  if (typeof window === 'undefined') {
    return;
  }
  const listName = customKey || 'localPollResponses';
  let localList = null;
  try {
    localList = await localForage.getItem(listName);
  } catch (err) {
    localList = null;
  }
  const noListOnStorage = !localList;
  const isUndefined = localList === 'undefined';
  // if no localPollResponses list, create new list
  if (noListOnStorage || isUndefined) {
    const firstResponsesList = { [modelID]: responseBody };
    try {
      await localForage.setItem(listName, firstResponsesList);
    } catch (err) {
      console.log(err);
    }
    return Promise.resolve(firstResponsesList);
  }
  // if prev list, update with new case
  if (typeof localList !== 'object') {
    return null;
  }
  const responseObject = { [modelID]: responseBody };
  const newResponsesList = { ...localList, ...responseObject };

  try {
    await localForage.setItem(listName, newResponsesList);
  } catch (err) {
    console.log(err);
  }

  return Promise.resolve(newResponsesList);
};

export const getLocalPollResponse = async (modelId, customKey) => {
  if (typeof window === 'undefined') {
    return;
  }
  const listName = customKey || 'localPollResponses';
  let localList = null;
  try {
    localList = await localForage.getItem(listName);
  } catch (err) {
    localList = null;
  }
  if (!localList) {
    return null;
  }
  if (localList === 'undefined') {
    return null;
  }
  const prevResponseBody = { ...localList[modelId] };

  return prevResponseBody;
};

export const saveLocalPollStage = async ({ pollKey, stage }) => {
  // console.log('on util');
  // console.log({ modelID, responseBody });
  if (typeof window === 'undefined') {
    return;
  }
  try {
    await localForage.setItem(pollKey, stage);
  } catch (err) {
    console.log(err);
  }
  return Promise.resolve();
};
export const getLocalPollStage = async modelId => {
  const pollKey = `poll@${modelId}`;
  let localStage = 0;
  try {
    localStage = await localForage.getItem(pollKey);
  } catch (err) {
    localStage = 0;
  }
  return Promise.resolve(parseInt(localStage));
};
